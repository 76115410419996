.App {
  text-align: center;
  background-color: #f4f4f9;
  padding: 20px;
  font-family: Arial, sans-serif;
  height: 100vh;
}

.App-header {
  margin: 50px auto;
  max-width: 600px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.App-header h1 {
  color: #333;
}

.App-header p {
  color: #666;
  line-height: 1.6;
}
